import React, { useContext } from "react";
import { columnCategories } from "../utils/columns";
import { useDispatch, useSelector } from "react-redux";
import { updateApplicationData, updateData, updateSalesForceData } from "../redux/actions/appActions";
import { getColumnFilterCookies } from "../utils/cookies";
import { AppContext, TableFilterContext } from "../App";

export default function useAggregation() {
  const { duplicateData, duplicateSalesforceData, columns } = useSelector((state) => state.app);
  const { tableRef, tableRefTwo, npsTableRef, npsTableRefTwo } = useContext(TableFilterContext);
  const { setCurrentPage, setCurrentPageTwo } = useContext(AppContext);
  const dispatch = useDispatch();

  const applyFilters = React.useCallback((data, columns, category) => {
    let filterConfiguration = null;
    if (category === columnCategories.PRODUCT_DATA || columnCategories.NPS_TAGGING_DATA) {
      filterConfiguration = getColumnFilterCookies(1);
    } else {
      filterConfiguration = getColumnFilterCookies(2);
    }
    if (!filterConfiguration) {
      return data;
    }

    let filteredCols = Object.keys(filterConfiguration);
    filteredCols = columns.filter((col) => filteredCols.includes(col.dataIndex));

    data = data.filter((row) => {
      let match = true;
      filteredCols.forEach((col) => {
        if (filterConfiguration[col.dataIndex].length > 0) {
          match &= !filterConfiguration[col.dataIndex].includes(row[col.dataIndex]);
        }
      });
      return match;
    });

    return data;
  }, []);

  const aggregate = React.useCallback((data, cols) => {
    let aggregatedData = [];
    const nonDimensionCols = ["NetSales", "Margin"];
    const visibleColIndices = cols.reduce((acc, col) => {
      if (col.visibility && (!nonDimensionCols.includes(col.dataIndex) || col.dataIndex === "HashKey")) {
        acc.push(col.dataIndex);
      }
      return acc;
    }, []);

    if (data && data.length)
      data.forEach((row) => {
        const aggRow = aggregatedData.find((aggRow) => {
          return visibleColIndices.every((col) => {
            return aggRow[col] === row[col];
          });
        });

        if (!aggRow) {
          const newRow = { ...row };
          newRow.Hashes = [row.HashKey];
          aggregatedData.push(newRow);
        } else {
          // aggregating non-dimensional columns (NetSales, Margin)
          aggRow.NetSales += row.NetSales;
          aggRow.Margin += row.Margin;
          aggRow.Hashes.push(row.HashKey);
        }
      });

    return aggregatedData;
  }, []);

  const resetFilters = React.useCallback(
    (data, filtered, table) => {
      if (table === 1) {
        if (tableRef) tableRef.reset(data, false);
        if (npsTableRef) npsTableRef.reset(filtered, false);
      } else {
        if (tableRefTwo) tableRefTwo.reset(data, false);
        if (npsTableRefTwo) npsTableRefTwo.reset(filtered, false);
      }
    },
    [tableRef, npsTableRef, tableRefTwo, npsTableRefTwo]
  );

  const resetPage = React.useCallback(
    (category) => {
      if (category === columnCategories.PRODUCT_DATA || category === columnCategories.NPS_TAGGING_DATA) {
        // table => 1
        setCurrentPage(1);
      } else if (category === columnCategories.PRODUCT_CUSTOMER_DATA || category === columnCategories.APPLICATION_TAGGING) {
        // table => 2
        setCurrentPageTwo(1);
      } else if (category === columnCategories.SFDC_COLUMNS) {
        // table => 2
        setCurrentPageTwo(1);
      }
    },
    [setCurrentPage, setCurrentPageTwo]
  );

  const aggregateData = React.useCallback(
    async (columns, category, initial_data = null, filtersApplied = true) => {
      if (category === columnCategories.NPS_TAGGING_DATA || category === columnCategories.APPLICATION_TAGGING) return;

      let _data = [];

      // _initial_data is passed when this function gets called on load.
      if (initial_data != null) {
        _data = initial_data;
      } else if (category === columnCategories.SFDC_COLUMNS) {
        _data = duplicateSalesforceData;
      } else {
        _data = duplicateData;
      }

      const aggregatedData = aggregate(_data, columns);
      resetPage(category);
      // apply filters on user selects/deselects a column. Filters will be applied automatically on the initial load
      const filteredData = filtersApplied ? applyFilters(aggregatedData, columns, category) : aggregatedData;

      if (category === columnCategories.PRODUCT_DATA) {
        dispatch(updateData(filteredData));
        if (filtersApplied) resetFilters(aggregatedData, filteredData, 1);
      } else if (category === columnCategories.PRODUCT_CUSTOMER_DATA) {
        dispatch(updateApplicationData(filteredData));
        if (filtersApplied) resetFilters(aggregatedData, filteredData, 2);
      } else if (category === columnCategories.SFDC_COLUMNS) {
        dispatch(updateSalesForceData(filteredData));
        if (filtersApplied) resetFilters(aggregatedData, filteredData, 2);
      }
      return filteredData;
    },
    [aggregate, applyFilters, dispatch, duplicateData, duplicateSalesforceData, resetFilters, resetPage]
  );

  const aggregateProductData = React.useCallback(
    (data, filtersApplied = false) =>
      aggregateData(columns[columnCategories.PRODUCT_DATA], columnCategories.PRODUCT_DATA, data, filtersApplied),
    [aggregateData, columns]
  );

  const aggregateApplicationData = React.useCallback(
    (data, filtersApplied = false) =>
      aggregateData(columns[columnCategories.PRODUCT_CUSTOMER_DATA], columnCategories.PRODUCT_CUSTOMER_DATA, data, filtersApplied),
    [aggregateData, columns]
  );

  const aggregateSalesforceDataOnLoad = React.useCallback(
    (data, filtersApplied = false) =>
      aggregateData(columns[columnCategories.SFDC_COLUMNS], columnCategories.SFDC_COLUMNS, data, filtersApplied),
    [aggregateData, columns]
  );

  const aggregateSAPDataOnLoad = React.useCallback(
    (data, filtersApplied = false) => {
      aggregateProductData(data, filtersApplied);
      aggregateApplicationData(data, filtersApplied);
    },
    [aggregateProductData, aggregateApplicationData]
  );

  return { aggregateData, aggregateSAPDataOnLoad, aggregateSalesforceDataOnLoad, aggregateProductData, aggregateApplicationData };
}
