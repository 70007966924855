import React, { useEffect } from "react";
import { runes } from "runes2";
import TimeStampRenderer from "./TimeStampRenderer";
import { AutoComplete, Input, InputNumber, Select } from "antd";
import { COLUMN } from "../../utils/columns";
import { getNpsYearOptions } from "../../utils/monthUtils";
import { useDispatch, useSelector } from "react-redux";
import { usePage } from "../../hooks/usePage";
import useTableUtils from "../../hooks/useTableUtils";
import { updateApplicationRow, updateSalesforceRow, updateSAPRow } from "../../redux/actions/appActions";
import useValidation from "../../hooks/useValidation";

const getDefaultDisabled = (row, table) => {
  if (table === 1) {
    if (row && row[COLUMN.QualifyAsNPS] === "YES") {
      return false;
    }
  } else if (table === 2) {
    if (row && row[COLUMN.QualifyAsApplication] === "YES") {
      return false;
    }
  }
  return true;
};

function TaggingRow(props) {
  const { columns, idx, row, table, updates, setUpdates, setRowsToBeUpdated } = props;
  const { getDataFromStore } = useTableUtils();
  const page = usePage();
  const [data, duplicateData] = getDataFromStore(page, table);

  //using row from the store, because, whenever store row's field gets updated.
  // the row we got here is not getting updated, as we're using the array.slice() for pagination purposes.
  const storeRow = data.find((_row) => _row.HashKey === row.HashKey);
  const dupRow = duplicateData.find((row) => row?.HashKey === storeRow?.HashKey);

  const [isDisabled, setIsDisabled] = React.useState(getDefaultDisabled(row, table));
  const salesYear = useSelector((state) => (page === "SAP" ? state.selections.salesYear : state.selections.salesYearSf));
  const { roles } = useSelector((state) => state.auth);
  const isProdViewer = roles && roles.includes("NPS_PROD_VIEWER");
  const dispatch = useDispatch();
  const [errors, setErrors] = React.useState({});
  const { user } = useSelector((state) => state.auth);
  const { validatePercentageExceeded, validatePercentageZero, validateCriteria, validateNpsYear, validateNPSTagging } = useValidation();

  React.useEffect(() => {
    const isDisabled = getDefaultDisabled(storeRow, table);
    setIsDisabled(isDisabled);
  }, [storeRow?.QualifyAsNPS, storeRow?.QualifyAsApplication, storeRow, table]);

  const resetRow = (dataIdx, value = null) => {
    if (dupRow[dataIdx] === "YES" || dupRow[dataIdx] === null) {
      return {
        [COLUMN.NPSPercentageNew]: null,
        [COLUMN.NPSPercentageRenew]: null,
        [COLUMN.NPSCriteria]: null,
        [COLUMN.SustnAdvgedSales]: null,
        [COLUMN.SustnAdvgedSalesPercentage]: null,
        [COLUMN.NPSYear]: null,
        [COLUMN.Project]: null,
        [COLUMN.Commentary]: null,
      };
    } else {
      return {
        [dataIdx]: value,
      };
    }
  };

  const getDropdownOptions = React.useCallback(
    (column) => {
      if (column.dataIndex === "NPSYear") return getNpsYearOptions(salesYear);
      return column?.options;
    },
    [salesYear]
  );

  const validate = React.useCallback(
    (_row) => {
      const _errors = {
        [COLUMN.NPSPercentageNew]: validatePercentageExceeded(_row, idx, table) || validatePercentageZero(_row, idx, table),
        [COLUMN.NPSPercentageRenew]: validatePercentageExceeded(_row, idx, table) || validatePercentageZero(_row, idx, table),
        [COLUMN.NPSCriteria]: validateCriteria(_row, idx, table),
        [COLUMN.NPSYear]: validateNpsYear(_row, idx, table),
        [COLUMN.QualifyAsNPS]: validateNPSTagging(_row, idx, table),
        [COLUMN.QualifyAsApplication]: validateNPSTagging(_row, idx, table),
      };
      setErrors(_errors);
      if (Object.values(_errors).includes(true)) {
        return false;
      }
    },
    [idx, table, validateCriteria, validateNPSTagging, validateNpsYear, validatePercentageExceeded, validatePercentageZero]
  );

  useEffect(() => {
    validate(storeRow);
  }, [validate, storeRow]);

  const onChangeHandler = (dataIndex, value = null) => {
    let _row = { ...row, [dataIndex]: value };

    if ((dataIndex === COLUMN.QualifyAsApplication || dataIndex === COLUMN.QualifyAsNPS) && value !== "YES") {
      _row = { ..._row, ...resetRow(dataIndex, value) };
    }

    setRowsToBeUpdated((prevState) => {
      let set = new Set(prevState);
      if (!set.has(idx)) {
        set.add(idx);
        let Checkbox = document.getElementById(`check-row-${table}-${idx}`);
        if (Checkbox) Checkbox.checked = true;
        return Array.from(set);
      } else return prevState;
    });

    let temp = updates;
    if (!temp[`${idx}`]) {
      temp[`${idx}`] = {
        HashKey: row.HashKey,
        Hashes: row.Hashes,
        TaggedBy: user.fullname,
      };
    }

    temp[`${idx}`][COLUMN.QualifyAsNPS] = _row[COLUMN.QualifyAsNPS];
    temp[`${idx}`][COLUMN.QualifyAsApplication] = _row[COLUMN.QualifyAsApplication];

    const setDefaults = () => {
      const yr = _row[COLUMN.NPSYear] == null ? _row["GMIDYear"] : _row[COLUMN.NPSYear];
      temp[`${idx}`][COLUMN.NPSYear] = yr;
      _row = {
        ..._row,
        [COLUMN.NPSYear]: yr,
        [COLUMN.NPSPercentageNew]: _row[COLUMN.NPSPercentageNew] || 0,
        [COLUMN.NPSPercentageRenew]: _row[COLUMN.NPSPercentageRenew] || 0,
      };
    };

    if (table === 1) {
      if (_row[COLUMN.QualifyAsNPS] === "YES") {
        temp[`${idx}`][COLUMN.QualifyAsApplication] = "NO";
        setDefaults();
      }
    }
    if (table === 2) {
      if (_row[COLUMN.QualifyAsApplication] === "YES") {
        temp[`${idx}`][COLUMN.QualifyAsNPS] = "NO";
        setDefaults();
      }
    }

    columns.forEach(({ dataIndex }) => {
      temp[`${idx}`][`${dataIndex}`] = _row[dataIndex];
    });
    temp[`${idx}`].TaggedBy = user.fullname;
    dispatch(setUpdates(temp));

    if (page === "SAP") {
      if (table === 1) dispatch(updateSAPRow(_row, idx));
      else dispatch(updateApplicationRow(_row, idx));
    } else dispatch(updateSalesforceRow(_row, idx));
  };

  return (
    <tr key={idx} id={`data-${table}-${idx}`}>
      {storeRow &&
        columns &&
        columns.map((column, index) =>
          column.visibility ? (
            <td key={index} className="px-2">
              {column.type === "dropdown" ? (
                <Select
                  status={errors[column.dataIndex] ? "error" : ""}
                  allowClear
                  className={`w-full`}
                  disabled={
                    (isDisabled && COLUMN.QualifyAsNPS !== column.dataIndex && COLUMN.QualifyAsApplication !== column.dataIndex) ||
                    isProdViewer
                  }
                  placeholder={column?.placeholder || column.title}
                  options={getDropdownOptions(column)}
                  id={`${column.dataIndex}-${table}-${idx}`}
                  value={storeRow[column.dataIndex]}
                  onChange={(value) => {
                    onChangeHandler(column.dataIndex, value);
                  }}
                />
              ) : column.type === "autocomplete" ? (
                <AutoComplete
                  status={errors[column.dataIndex] ? "error" : ""}
                  allowClear
                  className={`w-full`}
                  disabled={
                    (isDisabled && COLUMN.QualifyAsNPS !== column.dataIndex && COLUMN.QualifyAsApplication !== column.dataIndex) ||
                    isProdViewer
                  }
                  placeholder={column?.placeholder || column.title}
                  options={getDropdownOptions(column)}
                  id={`${column.dataIndex}-${table}-${idx}`}
                  value={storeRow[column.dataIndex]}
                  onChange={(value) => {
                    onChangeHandler(column.dataIndex, value);
                  }}
                />
              ) : column.type === "input" && column?.dataType === "number" ? (
                <InputNumber
                  status={errors[column.dataIndex] ? "error" : ""}
                  controls={false}
                  step={0}
                  className={`w-full `}
                  disabled={isDisabled || isProdViewer}
                  placeholder={column?.placeholder || column.title}
                  min={0}
                  max={100}
                  id={`${column.dataIndex}-${table}-${idx}`}
                  value={storeRow[column.dataIndex]}
                  onChange={(value) => {
                    onChangeHandler(column.dataIndex, value);
                  }}
                />
              ) : column.type === "input" ? (
                <Input
                  status={errors[column.dataIndex] ? "error" : ""}
                  id={`${column.dataIndex}-${table}-${idx}`}
                  value={storeRow[column.dataIndex]}
                  onChange={(event) => {
                    onChangeHandler(column.dataIndex, event.target.value);
                  }}
                  disabled={isDisabled || isProdViewer}
                  placeholder={column?.placeholder || column.title}
                  count={{
                    max: 300,
                    show: true,
                    exceedFormatter: (txt, { max }) => runes(txt).slice(0, max).join(""),
                  }}
                />
              ) : column.type === "textView" ? (
                column.dataIndex === "lastmodifiedtimestamp" ? (
                  <TimeStampRenderer timestamp={row[column.dataIndex]} />
                ) : (
                  <span>{row[column.dataIndex]}</span>
                )
              ) : null}
            </td>
          ) : null
        )}
    </tr>
  );
}

export default TaggingRow;
