import { AutoComplete, Button, Drawer, Form, Select, Space, TreeSelect } from "antd";
import React, { memo, useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearFilterCookies, getFilterCookies, updateFilterCookies } from "../utils/cookies";
import { setAppError } from "../redux/actions/appActions";
import { AppContext } from "../App";
import { useLocation, useNavigate } from "react-router-dom";
import { getMonthOptions, getSelectedGmidMonths, getYearOptions, validateYear } from "../utils/monthUtils";
import { updateUserSelection } from "../redux/actions/userSelectionActions";
import useData from "../hooks/useData";

const dataSourceOptions = [
  {
    label: "SAP",
    value: "SAP",
  },
  {
    label: "SFDC",
    value: "SFDC",
  },
];

function FilterPopup({ open, setOpen }) {
  const { filters } = useSelector((state) => state.app);
  const [cachedFilters, setCachedFilters] = useState(getFilterCookies());
  const [filterForm] = Form.useForm();
  const dispatch = useDispatch();
  const [businessSelected, setBusinessSelected] = useState(cachedFilters?.hier2);
  const [reportingUnitSelected, setReportingUnitSelected] = useState(cachedFilters?.hier5);
  const { hasSFDCAccess, hasAccessToApp } = useSelector((state) => state.app);
  const monthOptions = getMonthOptions();
  const yearOptions = getYearOptions();
  const [businessoptions, setBusinessOptions] = useState([]);
  const [loboptions, setLobOptions] = useState([]);
  const [reportingUnitOptions, setReprotingUnitOptions] = useState([]);
  const { salesYear } = useSelector((state) => state.selections);
  const { getData } = useData();

  const {
    GMIDMonthLocal,
    setGMIDMonthLocal,
    GMIDYearLocal,
    setGMIDYearLocal,
    setRowsToBeUpdated,
    setRowsToBeUpdatedForTableTwo,
    filterFormDisabled,
    setfilterFormDisabled,
    messageApi,
  } = useContext(AppContext);

  const updateFilters = React.useCallback(() => {
    updateFilterCookies(filterForm.getFieldsValue());
  }, [filterForm]);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    try {
      if (filters) {
        setBusinessOptions(
          Object.keys(filters)
            .map((value) => {
              return {
                label: value,
                value: value,
              };
            })
            .sort((a, b) => (a.value < b.value ? -1 : 1))
        );
        if (filters && cachedFilters?.hier2) {
          setLobOptions(
            Object.keys(filters[cachedFilters.hier2])
              .map((value) => {
                return {
                  label: value,
                  value: value,
                };
              })
              .sort((a, b) => (a.value < b.value ? -1 : 1))
          );
          setReprotingUnitOptions(filters[cachedFilters.hier2][cachedFilters.hier3]);
        }
      }
    } catch (err) {
      clearFilterCookies();
      dispatch(setAppError(true));
      setOpen(true);
    }
  }, [cachedFilters?.hier2, cachedFilters?.hier3, dispatch, filters, open, setOpen]);

  useEffect(() => {
    setCachedFilters(getFilterCookies());
  }, []);
  useEffect(() => {
    if (location.pathname === "/") setfilterFormDisabled(false);
    else setfilterFormDisabled(true);
  }, [location]);

  const onFinishHandler = React.useCallback(async () => {
    setOpen(false);
    updateFilters();
    dispatch(updateUserSelection({ gmidMonth: GMIDMonthLocal, gmidYear: GMIDYearLocal }));
    const dataSource = filterForm.getFieldValue("dataSource") || "SAP";
    if (dataSource === "SAP") {
      navigate("/");
      const checkBoxes = document.getElementsByClassName("row-select-check");
      for (let i = 0; i < checkBoxes.length; i++) {
        if (checkBoxes[i]) checkBoxes[i].checked = false;
      }
      setRowsToBeUpdated([]);
      setRowsToBeUpdatedForTableTwo([]);
      messageApi.open({
        type: "loading",
        content: "Applying filters, please wait",
        duration: 60,
        key: "filters",
      });
      await getData({
        gmidmonth: GMIDMonthLocal,
        gmidyear: GMIDYearLocal,
        salesyear: salesYear,
        ...filterForm.getFieldsValue(),
      })
        .then((res) => {
          messageApi.open({
            key: "filters",
            type: "success",
            duration: 5,
            content: "Filters applied",
          });
        })
        .catch((er) => {
          dispatch(setAppError(true));
        });
    } else {
      navigate("/salesforce");
    }
  }, [
    GMIDMonthLocal,
    GMIDYearLocal,
    dispatch,
    filterForm,
    getData,
    messageApi,
    navigate,
    salesYear,
    setOpen,
    setRowsToBeUpdated,
    setRowsToBeUpdatedForTableTwo,
    updateFilters,
  ]);

  const businessChangeHandler = React.useCallback(
    (value) => {
      setBusinessSelected(value);
      filterForm.setFieldValue("hier5", null);
      filterForm.setFieldValue("hier3", null);
      setLobOptions(
        Object.keys(filters[value]).map((value) => {
          return {
            label: value,
            value: value,
          };
        })
      );
    },
    [filterForm, filters]
  );

  const lobChangeHandler = React.useCallback(
    (value) => {
      filterForm.setFieldValue("hier5", null);
      setReprotingUnitOptions(filters[businessSelected][value].sort((a, b) => (a.value < b.value ? -1 : 1)));
    },
    [businessSelected, filterForm, filters]
  );

  const reportingunitChangehandler = React.useCallback((value) => setReportingUnitSelected(value), []);

  const dataSourceChangeHandler = React.useCallback(
    (value) => {
      if (value === "SAP") setfilterFormDisabled(false);
      else setfilterFormDisabled(true);
    },
    [setfilterFormDisabled]
  );

  return (
    <Drawer
      placement="left"
      rootClassName="filter-drawer"
      open={hasAccessToApp && open}
      onOk={() => setOpen(false)}
      onCancel={() => setOpen(false)}
      onClose={() => setOpen(false)}
      closeable={false}
      closeIcon={null}
      maskClosable={false}
      destroyOnClose={true}
      width={480}
      mask={false}
      style={{ margin: 0, padding: 0 }}
    >
      <Form form={filterForm} layout="vertical" onFinish={onFinishHandler}>
        <h1 className="bold text-red-600">Welcome to the NPS Tagging App!</h1>
        <span>Please select and confirm the following to continue&nbsp;:</span>
        <div className="flex flex-col gap-3">
          <Form.Item
            label={<h4>Business</h4>}
            name="hier2"
            rules={[
              {
                required: !filterFormDisabled,
                message: "Business is required",
              },
            ]}
            initialValue={cachedFilters?.hier2}
            style={{ marginBottom: -10 }}
          >
            <Select
              disabled={filterFormDisabled}
              options={businessoptions}
              loading={!filters}
              onChange={businessChangeHandler}
              placeholder="Business"
              style={{ marginTop: -25 }}
              showSearch
            />
          </Form.Item>
          <Form.Item
            label={<h4>Line of Business</h4>}
            name="hier3"
            rules={[
              {
                required: !filterFormDisabled,
                message: "Line of Business is required",
              },
            ]}
            initialValue={cachedFilters?.hier3}
            style={{ marginBottom: -5 }}
          >
            <Select
              disabled={filterFormDisabled}
              options={loboptions}
              loading={!filters}
              placeholder="Line of Business"
              style={{ marginTop: -25 }}
              showSearch
              onChange={lobChangeHandler}
            />
          </Form.Item>
          <Form.Item
            label={<h4>Reporting Unit</h4>}
            name="hier5"
            rules={[
              {
                required: !filterFormDisabled,
                message: "Reporting Unit is required",
              },
            ]}
            initialValue={cachedFilters?.hier5}
            style={{ marginBottom: -5 }}
          >
            <Select
              loading={!filters}
              disabled={filterFormDisabled}
              options={reportingUnitOptions}
              placeholder="Reporting Unit"
              style={{ marginTop: -25 }}
              showSearch
              onChange={reportingunitChangehandler}
            />
          </Form.Item>
          {hasSFDCAccess && reportingUnitSelected === "LIVEO" ? (
            <Form.Item
              label={<h4>Data source</h4>}
              name="dataSource"
              rules={[
                {
                  required: true,
                  message: "Data source is required",
                },
              ]}
              initialValue={location.pathname === "/" ? "SAP" : "SFDC"}
              style={{ marginBottom: -5 }}
            >
              <Select
                options={dataSourceOptions}
                placeholder="Data source"
                style={{ marginTop: -25 }}
                showSearch
                onChange={dataSourceChangeHandler}
              />
            </Form.Item>
          ) : null}
          <Form.Item
            label={
              <h4 className="flex flex-row items-center">
                {!filterFormDisabled ? <span className="text-red-400 text-lg">*</span> : null}
                &nbsp; Material Created Date{" "}
              </h4>
            }
            rules={[
              {
                required: !filterFormDisabled,
              },
            ]}
            style={{ marginTop: 0 }}
          >
            <Space direction="horizontal" style={{ marginTop: "-100px" }} className="w-full">
              <span className="w-1/2">
                &nbsp; GMID Year :
                <AutoComplete
                  status={validateYear(GMIDYearLocal) ? "" : "error"}
                  disabled={filterFormDisabled}
                  style={{ width: 225 }}
                  placeholder="Year"
                  options={yearOptions}
                  value={GMIDYearLocal}
                  onChange={(year) => {
                    setGMIDYearLocal(year);
                  }}
                />
              </span>
              <span className="w-1/2">
                &nbsp;GMID Month:
                <TreeSelect
                  style={{ width: 225, height: 30, overflow: "clip" }}
                  placeholder="Month"
                  value={GMIDMonthLocal}
                  disabled={filterFormDisabled}
                  treeData={monthOptions}
                  treeCheckable
                  treeDefaultExpandAll
                  treeIcon={true}
                  onChange={(month) => {
                    const _month = getSelectedGmidMonths(month);
                    setGMIDMonthLocal(_month);
                  }}
                />
              </span>
            </Space>
          </Form.Item>
        </div>
        <div className="flex flex-row justify-between items-center">
          <span className="text-red-500"> * Required fields</span>
          <Button type="primary" htmlType="submit">
            Apply Filters
          </Button>
        </div>
      </Form>
    </Drawer>
  );
}

export default memo(FilterPopup);
