import { useSelector } from "react-redux";

export default function useTableUtils() {
  const { data, applicationData, duplicateData, salesforceData, duplicateSalesforceData } = useSelector((state) => state.app);

  const getDataFromStore = (page, table) => {
    if (page === "SAP") {
      if (table === 1) return [data, duplicateData];
      else return [applicationData, duplicateData];
    } else return [salesforceData, duplicateSalesforceData];
  };
  return { getDataFromStore };
}
