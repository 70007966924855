import axios from "axios";
import { store } from "../redux/store";

export const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_FAST_API_APP_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

axiosClient.interceptors.request.use(
  (config) => {
    const token = store.getState().auth.token;
    const idToken = store.getState().auth.idToken;

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
      config.headers.id = `${idToken}`;
      config.timeout = 50 * 1000;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

axiosClient.interceptors.response.use(
  (response) => response.data,
  (error) => Promise.reject(error)
);
