import { useCallback } from "react";
import { usePage } from "./usePage";
import { useSelector } from "react-redux";
import { yearRegex } from "../utils/monthUtils";

export default function useValidation() {
  const page = usePage();
  const { duplicateData, duplicateSalesforceData } = useSelector((state) => state.app);

  const validateNPSTagging = useCallback(
    (row, idx, table) => {
      const _data = page === "salesforce" ? duplicateSalesforceData : duplicateData;
      const dupRow = _data.find((item) => item?.HashKey === row?.HashKey);

      if (
        dupRow &&
        dupRow.NPSYear &&
        Number(dupRow.NPSYear) === Number(row?.NPSYear) &&
        ((row?.QualifyAsNPS === "YES" && dupRow.QualifyAsApplication === "YES") ||
          (row?.QualifyAsApplication === "YES" && dupRow.QualifyAsNPS === "YES"))
      ) {
        return true;
      }
      return false;
    },
    [duplicateData, duplicateSalesforceData, page]
  );

  const validatePercentageExceeded = useCallback((row, idx, table) => {
    if (row?.NPSPercentageNew + row?.NPSPercentageRenew > 100) {
      return true;
    }
    return false;
  }, []);

  const validatePercentageZero = useCallback((row, idx, table) => {
    if (row?.NPSPercentageNew === 0 && row?.NPSPercentageRenew === 0) {
      return true;
    }
    return false;
  }, []);

  const validateCriteria = useCallback((row, idx, table) => {
    if (table === 1) {
      if (row?.QualifyAsNPS === "YES" && !row?.NPSCriteria) {
        return true;
      }
    } else if (table === 2) {
      if (row?.QualifyAsApplication === "YES" && !row?.NPSCriteria) {
        return true;
      }
    }
    return false;
  }, []);

  const validateNpsYear = useCallback((row, idx, table) => {
    if (table === 1) {
      if (row?.QualifyAsNPS === "YES") return !row?.NPSYear || !yearRegex.test(row?.NPSYear);
    } else {
      if (row?.QualifyAsApplication === "YES") return !row?.NPSYear || !yearRegex.test(row?.NPSYear);
    }
    return false;
  }, []);

  const validateTagging = useCallback(
    (updatedRows, updates, table) => {
      let percentExceeded = false;
      let percentZero = false;
      let isCriteriaError = false;
      let NPSYearError = false;
      let npsTagError = false;
      updatedRows.forEach((idx) => {
        if ((updates[idx] && updates[idx].QualifyAsNPS === "YES") || updates[idx].QualifyAsApplication === "YES") {
          percentExceeded = percentExceeded || validatePercentageExceeded(updates[idx], idx, table);
          percentZero = percentZero || validatePercentageZero(updates[idx], idx, table);
          isCriteriaError = isCriteriaError || validateCriteria(updates[idx], idx, table);
          NPSYearError = NPSYearError || validateNpsYear(updates[idx], idx, table);
          npsTagError = npsTagError || validateNPSTagging(updates[idx], idx, table);
        }
      });

      return [percentExceeded, percentZero, isCriteriaError, NPSYearError, npsTagError];
    },
    [validateCriteria, validateNPSTagging, validateNpsYear, validatePercentageExceeded, validatePercentageZero]
  );

  return { validateCriteria, validateNPSTagging, validateNpsYear, validatePercentageExceeded, validatePercentageZero, validateTagging };
}
