import TableFilter from "react-table-filter";
import "react-table-filter/lib/styles.css";
import { getColumnFilterCookies } from "../../utils/cookies";
import ColumnNameRenderer from "../ColumnNameRenderer";
import TaggingRow from "./TaggingRow";
import useFilterUtils from "../../hooks/useFilterUtils";

function NPSTable({ columns, data, pageSize, currentPage, setRowsToBeUpdated, table, updates, setUpdates, setCurrentPage, setTableRef }) {
  const { onFilterChange } = useFilterUtils();

  const rowIndex = (idx) => idx + (currentPage - 1) * pageSize;

  return data ? (
    <table>
      <thead>
        <TableFilter
          rows={data}
          initialFilters={getColumnFilterCookies(table)}
          onFilterUpdate={(newData, filterConfiguration) => {
            onFilterChange(newData, filterConfiguration, setCurrentPage, table);
          }}
          ref={(node) => setTableRef(node)}
        >
          {columns
            .filter((column) => column.visibility)
            .map((column) =>
              column.visibility ? (
                <th
                  key={column.dataIndex}
                  filterkey={column.dataIndex}
                  style={{
                    width: column?.width || 150,
                    minWidth: column?.width || 150,
                  }}
                >
                  <ColumnNameRenderer dataIndex={column.dataIndex}>{column.title}</ColumnNameRenderer>
                </th>
              ) : null
            )}
        </TableFilter>
      </thead>
      <tbody>
        {data &&
          data
            .slice((currentPage - 1) * pageSize, currentPage * pageSize)
            .map((row, rindex) => (
              <TaggingRow
                key={row?.Hashkey}
                columns={columns}
                idx={rowIndex(rindex)}
                row={row}
                table={table}
                updates={updates}
                setUpdates={setUpdates}
                setRowsToBeUpdated={setRowsToBeUpdated}
              />
            ))}
      </tbody>
    </table>
  ) : null;
}

export default NPSTable;
